import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Edges from "../edges"
import Textarea from "./Textarea"

export default function TextImage({ alignment, content, image }) {
  const img = getImage(image.localFile)

  return (
    <EdgesContainer size="md">
      <Container alignment={alignment}>
        <ImageContainer alignment={alignment}>
          {img && <StyledImage image={img} alt={image?.altText || ""} />}
        </ImageContainer>
        <TextContainer>
          <TextEdges size="lg">
            <Textarea text={content} />
          </TextEdges>
        </TextContainer>
      </Container>
    </EdgesContainer>
  )
}

const EdgesContainer = styled(Edges)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Container = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.alignment === "right" ? "wrap-reverse" : "wrap")};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  order: ${(props) => (props.alignment === "right" ? 3 : 1)};
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: calc(50% - 15px);
    justify-content: ${(props) => (props.alignment === "right" ? "flex-end" : "flex-start")};
  }
`

const StyledImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 380px;
  object-fit: cover;

  @media (min-width: 576px) {
    width: 550px;
  }

  @media (min-width: 768px) {
    height: 450px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  order: 2;

  @media (min-width: 768px) {
    width: calc(50% - 15px);
  }
`

const TextEdges = styled(Edges)`
  display: flex;
  justify-content: center;
  align-items: center;
`
